import "core-js/modules/es.array.push.js";
import { defineComponent, reactive, toRefs, computed, watch, nextTick, onMounted } from "vue";
import { useStore } from "vuex";
import { useDebounce } from "@/hooks/core/useDebounce";
import emitter from "@/eventBus/";
import { sleep } from "@/utils/sleep";
export default defineComponent({
  emits: ["handleCheckChange", "handleClick", "handleDoubleClick", "handleAddData", "handleIssued", "handleCheck"],
  props: {
    //是否显示复选框
    treeType: {
      type: Boolean,
      default: false
    },
    // 是否展示搜索功能
    isSearch: {
      type: Boolean,
      default: true
    },
    // 是否展示查询按钮
    isBtn: {
      type: Boolean,
      default: false
    },
    // 输入框提示文本
    placeholder: {
      type: String,
      default: "请输入关键词"
    },
    // 输入框背景是否展示
    isBackground: {
      type: Boolean,
      default: true
    },
    // 是否开启单击
    isClick: {
      type: Boolean,
      default: true
    },
    // 是否开启双击
    isDoubleClick: {
      type: Boolean,
      default: false
    },
    isStyle: {
      type: Object,
      default: {
        width: "100%"
      }
    },
    //查询数据类型 0为仅查组织树形，1为增加组织下的单位，2为增加单位下的部门，3为增加部门下的岗位类型
    type: {
      type: Number,
      default: 0
    },
    //设置默认展的node-key
    defaultExpandedKeys: {
      type: Array,
      default() {
        return [];
      }
    },
    // 默认勾选的node-key
    defaultCheckedKeys: {
      type: Array,
      default() {
        return [];
      }
    },
    currentNodeKey: {
      type: [String, Number],
      default: "BSJ"
    },
    // 点击节点及勾选节点
    checkOnClickNode: {
      type: Boolean,
      default: false
    },
    // 在显示复选框的情况下，是否严格的遵循父子不互相关联的做法
    checkStrictly: {
      type: Boolean,
      default: false
    },
    renderAfterExpand: {
      type: Boolean,
      default: true
    },
    defaultExpandAll: {
      type: Boolean,
      default: false
    },
    // 叶子节点 点击节点及勾选节点
    checkOnClickLeaf: {
      type: Boolean,
      deafult: false
    },
    //下发按钮
    isIssued: {
      type: Boolean,
      default: false
    },
    //添加按钮
    isAdd: {
      type: Array,
      default: []
    },
    //添加按钮
    isAddText: {
      type: Array,
      default: []
    },
    //选中typeId
    activeTypeId: {
      type: String
    },
    //是否显示员工数量
    isShowStudentNum: {
      type: Boolean,
      default: false
    }
  },
  setup(props, {
    emit
  }) {
    const {
      isDoubleClick,
      isClick,
      type
    } = props;
    const {
      state,
      dispatch
    } = useStore();
    const refData = reactive({
      height: 700,
      scrollbarRef: null,
      allOnoff: false,
      activeKey: null,
      treeLoading: false,
      treeTime: new Date().getTime(),
      tree: null,
      filterText: "",
      list: [],
      defaultProps: {
        children: "children",
        label: "name",
        value: "typeId"
      },
      loading: false,
      searchValue: ""
    });
    const treeList = computed(() => state.treeData.length ? state.treeData[props.type] : []);
    const timeString = computed(() => refData.treeTime);
    const isSchoolUser = computed(() => state.userInfo ? state.userInfo.hasOwnProperty("schoolId") : false);
    // 展开的 node-key
    const treeExpandKeys = computed(() => {
      const list = state.treeData.length ? state.treeData[props.type] : [];
      // tree 第一级节点
      const key = list && list.length ? [list[0].typeId] : [];
      return [...key, ...props.defaultExpandedKeys];
    });
    watch(() => timeString.value, () => {
      nextTick(() => {
        props.activeTypeId && refData.tree.setCurrentKey(props.activeTypeId);
      });
    });
    watch(() => treeList.value, val => {
      nextTick(() => {
        refData.tree && refData.tree.filter('');
        props.activeTypeId && refData.tree.setCurrentKey(props.activeTypeId);
      });
    });
    // 点击节点回调
    const handleClick = (data, node) => {
      // 叶子节点, 点击及勾选节点
      if (props.treeType && props.checkOnClickLeaf && !node.disabled && node.isLeaf) {
        // 会触发check-chang事件
        refData.activeKey = data.typeId;
        refData.tree.setChecked(data.typeId, !node.checked);
        emit("handleCheck", getCheckedNodes());
      }
      refData.filterText = data.name;
      isClick && emit("handleClick", data);
    };
    // 点击节点回调
    const handleDoubleClick = data => {
      emit("handleDoubleClick", data);
    };
    //图标判断
    const handleIcon = name => {
      return name == "org" ? "icon-zuzhi1" : name == "school" ? "icon-xuexiao1" : name == "grade" ? "icon-nianji1" : "icon-banji1";
    };
    //模糊搜索
    const remoteMethod = query => {
      const treeData = [];
      function handleTree(arr) {
        arr.map(item => {
          treeData.push({
            ...item,
            searchName: item.type == "org" ? item.name : `${item.parentName ? item.parentName + " > " : ""}${item.name}`
          });
          if (item.hasOwnProperty("children")) {
            handleTree(item.children);
          }
        });
      }
      handleTree(treeList.value);
      if (query !== "") {
        refData.loading = true;
        setTimeout(() => {
          refData.loading = false;
          refData.list = treeData.filter(item => {
            return item.name.toLowerCase().indexOf(query.toLowerCase()) > -1;
          });
        }, 200);
      } else {
        refData.list = [];
      }
    };
    //定位展开到当前节点
    const filterMethod = (query, node) => {
      return node.typeId.indexOf(query) !== -1;
    };
    //勾选节点时
    const handleChange = val => {
      //当清空搜索词
      !refData.filterText && refData.tree.filter(refData.filterText);
      if (val && !refData.filterText) return;
      refData.tree.setCurrentKey(refData.filterText);
      const currentNode = refData.tree.getCurrentNode();
      if (currentNode && props.treeType && !currentNode.disabled && !currentNode.checked) {
        const activeList = [];
        if (currentNode.hasOwnProperty("children")) {
          function checkboxFn(arr) {
            arr.map(item => {
              refData.tree.setChecked(item.typeId, true);
              activeList.push(item.typeId);
              if (item.hasOwnProperty("children")) {
                checkboxFn(item.children);
              }
            });
          }
          checkboxFn(currentNode.children);
        } else {
          refData.tree.setChecked(currentNode, true);
        }
      }
      if (currentNode) {
        setViewPort();
        emit("handleClick", currentNode);
        refData.tree.filter(currentNode.typeId);
      }
      props.treeType && emit("handleCheck", refData.tree.getCheckedKeys());
    };
    //新增按钮
    const handleAddData = data => {
      emit("handleAddData", data);
    };
    //下发
    const handleIssued = data => {
      emit("handleIssued", data);
    };
    //复选框触发
    const handleCheck = (data, {
      checkedNodes
    }) => {
      refData.allOnoff = refData.tree.getCheckedKeys().length ? true : false;
      emit("handleCheck", checkedNodes);
    };
    // 复选框改变事件
    function handleCheckChange(data, checked) {
      emit("handleCheckChange", data, checked);
    }
    const handleName = type => {
      let active = 0;
      props.isAdd.map((item, index) => {
        type == item && (active = index);
      });
      return props.isAddText[active];
    };
    /**
     * 通过 key / data 设置某个节点的勾选状态，使用此方法必须设置 node-key 属性
     * key: 勾选节点的 key 或者 data
     * checked:节点是否选中
     * deep: 是否设置子节点
     */
    function setChecked(key, checked, deep = false) {
      // 会触发check-change事件
      refData.tree.setChecked(key, checked, deep);
      const node = refData.tree.getNode(key);
      // 叶子节点 勾选节点, 及选中节点
      if (props.checkOnClickLeaf && !node.disabled && node.isLeaf) {
        const currentKey = refData.tree.getCurrentKey();
        if (checked) {
          // 勾选 高亮当前节点
          refData.tree.setCurrentKey(key);
        } else if (key === currentKey) {
          refData.tree.setCurrentKey(null);
        }
      }
    }
    const [debounceOnAdd] = useDebounce(handleAddData, 200);
    const handleShow = () => {
      refData.treeTime = new Date().getTime();
    };
    //删除树组织数据
    const handleRemove = typeId => {
      dispatch('getTreeData');
    };
    //取消与全选
    const handleCloseAll = () => {
      if (!refData.allOnoff) {
        refData.tree.setChecked(treeList.value[0].typeId, true, true);
      } else {
        refData.tree.setChecked(treeList.value[0].typeId, false, true);
      }
      emit("handleCheck", getCheckedNodes());
      refData.allOnoff = !refData.allOnoff;
    };
    //获取当前全部选中node
    const getCheckedNodes = () => {
      return refData.tree.getCheckedNodes();
    };
    //设置当前视野
    const setViewPort = (domname = '.is-current') => {
      nextTick(() => {
        setTimeout(() => {
          const dom = document.querySelector(domname);
          dom.scrollIntoView(true);
        }, 600);
      });
    };
    nextTick(() => {
      emitter.on("handleShow", handleShow);
    });
    onMounted(async () => {
      await sleep(200);
      //refData.height = refData.scrollbarRef.scrollbar$?.clientHeight;
      await sleep(600);
    });
    return {
      ...toRefs(refData),
      handleClick,
      handleDoubleClick,
      treeList,
      handleIcon,
      remoteMethod,
      handleChange,
      handleIssued,
      debounceOnAdd,
      handleName,
      handleCheck,
      handleCheckChange,
      setChecked,
      isSchoolUser,
      handleRemove,
      treeExpandKeys,
      handleCloseAll,
      getCheckedNodes,
      setViewPort,
      filterMethod
    };
  }
});