import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-6a47c824"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "TreeSelect__search"
};
const _hoisted_2 = {
  class: "custom-tree-node"
};
const _hoisted_3 = ["onDblclick"];
const _hoisted_4 = {
  key: 1,
  class: "point"
};
const _hoisted_5 = {
  key: 1,
  class: "point"
};
const _hoisted_6 = {
  key: 2
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_tooltip = _resolveComponent("el-tooltip");
  const _component_el_option = _resolveComponent("el-option");
  const _component_el_select = _resolveComponent("el-select");
  const _component_el_button = _resolveComponent("el-button");
  const _component_Icon = _resolveComponent("Icon");
  const _component_el_tree_v2 = _resolveComponent("el-tree-v2");
  const _component_el_scrollbar = _resolveComponent("el-scrollbar");
  const _directive_loading = _resolveDirective("loading");
  return _withDirectives((_openBlock(), _createElementBlock("div", {
    class: "TreeSelect",
    style: _normalizeStyle(_ctx.isStyle ? _ctx.isStyle : {
      width: '100%'
    }),
    "element-loading-text": "加载中..."
  }, [_createElementVNode("div", _hoisted_1, [_ctx.isSearch ? (_openBlock(), _createBlock(_component_el_select, {
    key: 0,
    modelValue: _ctx.filterText,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.filterText = $event),
    filterable: "",
    remote: "",
    clearable: "",
    "reserve-keyword": "",
    class: _normalizeClass({
      background_none: _ctx.isBackground,
      w_100: 'w_100',
      treeType: _ctx.treeType ? 'treeType' : ''
    }),
    placeholder: _ctx.placeholder,
    "remote-method": _ctx.remoteMethod,
    onChange: _ctx.handleChange,
    loading: _ctx.loading
  }, {
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, item => {
      return _openBlock(), _createBlock(_component_el_option, {
        key: item.typeId,
        label: item.searchName,
        value: item.typeId,
        class: "TreeSelect_options"
      }, {
        default: _withCtx(() => [_createElementVNode("span", null, [_createVNode(_component_el_tooltip, {
          class: "item",
          effect: "dark",
          content: item.searchName,
          placement: "left"
        }, {
          default: _withCtx(() => [_createElementVNode("span", null, _toDisplayString(item.searchName), 1)]),
          _: 2
        }, 1032, ["content"])]), _createElementVNode("span", {
          class: _normalizeClass(item.type)
        }, _toDisplayString(item.type == "org" ? "组织" : item.type == "school" ? "单位" : item.type == "class" ? "岗位类型" : "部门"), 3)]),
        _: 2
      }, 1032, ["label", "value"]);
    }), 128))]),
    _: 1
  }, 8, ["modelValue", "class", "placeholder", "remote-method", "onChange", "loading"])) : _createCommentVNode("", true), _ctx.treeType ? (_openBlock(), _createBlock(_component_el_button, {
    key: 1,
    type: "primary",
    onClick: _ctx.handleCloseAll
  }, {
    default: _withCtx(() => [_createTextVNode(_toDisplayString(!_ctx.allOnoff ? "全选" : "取消选择"), 1)]),
    _: 1
  }, 8, ["onClick"])) : _createCommentVNode("", true)]), _createVNode(_component_el_scrollbar, {
    ref: "scrollbarRef"
  }, {
    default: _withCtx(() => [_createVNode(_component_el_tree_v2, {
      class: "filter-tree",
      height: _ctx.height,
      "highlight-current": "",
      data: _ctx.treeList,
      onCheck: _ctx.handleCheck,
      onCheckChange: _ctx.handleCheckChange,
      "show-checkbox": _ctx.treeType,
      props: _ctx.defaultProps,
      onNodeClick: _ctx.handleClick,
      "default-expanded-keys": _ctx.treeExpandKeys,
      "default-checked-keys": _ctx.defaultCheckedKeys,
      "current-node-key": _ctx.currentNodeKey,
      "check-on-click-node": _ctx.checkOnClickNode,
      "check-strictly": _ctx.checkStrictly,
      "filter-method": _ctx.filterMethod,
      ref: "tree"
    }, {
      default: _withCtx(({
        node,
        data
      }) => [_createElementVNode("span", _hoisted_2, [_ctx.isDoubleClick ? (_openBlock(), _createElementBlock("span", {
        key: 0,
        onDblclick: $event => _ctx.handleDoubleClick(data)
      }, [data.parentId != -1 ? (_openBlock(), _createBlock(_component_Icon, {
        key: 0,
        name: _ctx.handleIcon(data.type),
        style: {
          "font-size": "14px"
        }
      }, null, 8, ["name"])) : _createCommentVNode("", true), data.leaf ? (_openBlock(), _createElementBlock("span", _hoisted_4)) : _createCommentVNode("", true), _createTextVNode(" " + _toDisplayString(node.label) + " ", 1), data.total ? (_openBlock(), _createBlock(_component_el_tooltip, {
        key: 2,
        class: "item",
        effect: "dark",
        content: `总数：${data.total}位`,
        placement: "bottom"
      }, {
        default: _withCtx(() => [_createElementVNode("span", null, "（" + _toDisplayString(data.total) + "）", 1)]),
        _: 2
      }, 1032, ["content"])) : _createCommentVNode("", true)], 40, _hoisted_3)) : (_openBlock(), _createElementBlock("span", {
        key: 1,
        class: _normalizeClass(!data.hasOwnProperty('children') ? '' : '')
      }, [data.parentId != -1 ? (_openBlock(), _createBlock(_component_Icon, {
        key: 0,
        name: _ctx.handleIcon(data.type),
        style: {
          "font-size": "14px"
        }
      }, null, 8, ["name"])) : _createCommentVNode("", true), data.leaf ? (_openBlock(), _createElementBlock("span", _hoisted_5)) : _createCommentVNode("", true), _createTextVNode(" " + _toDisplayString(node.label) + " ", 1), _ctx.isShowStudentNum ? (_openBlock(), _createElementBlock("span", _hoisted_6, "(" + _toDisplayString(data.total) + ")", 1)) : _createCommentVNode("", true)], 2)), ['org', 'school', 'class'].includes(data.type) && _ctx.isIssued ? (_openBlock(), _createBlock(_component_el_tooltip, {
        key: 2,
        style: {
          "float": "right",
          "margin-right": "10px"
        },
        class: "item",
        effect: "dark",
        content: `群发指令`,
        placement: "bottom"
      }, {
        default: _withCtx(() => [_createVNode(_component_Icon, {
          name: "icon-a-xiafa1",
          class: "active_color",
          style: {
            "font-size": "18px"
          },
          onClick: _withModifiers($event => _ctx.handleIssued(data), ["stop"])
        }, null, 8, ["onClick"])]),
        _: 2
      }, 1024)) : _createCommentVNode("", true), _ctx.isAdd.includes(data.type) && !(node.level == 1 && _ctx.isSchoolUser) ? (_openBlock(), _createBlock(_component_el_tooltip, {
        key: 3,
        style: {
          "float": "right",
          "margin-right": "10px"
        },
        class: "item",
        effect: "dark",
        content: `${_ctx.handleName(data.type)}`,
        placement: "bottom"
      }, {
        default: _withCtx(() => [_createVNode(_component_Icon, {
          name: "icon-a-tianjia",
          style: _normalizeStyle([{
            "font-size": "18px"
          }, {
            color: data.type == 'org' ? '#0091FF' : data.type == 'grade' ? '#FF7D27' : '#2CC564'
          }]),
          class: "active_color",
          onClick: _withModifiers($event => _ctx.debounceOnAdd(data), ["stop"])
        }, null, 8, ["style", "onClick"])]),
        _: 2
      }, 1032, ["content"])) : _createCommentVNode("", true)])]),
      _: 1
    }, 8, ["height", "data", "onCheck", "onCheckChange", "show-checkbox", "props", "onNodeClick", "default-expanded-keys", "default-checked-keys", "current-node-key", "check-on-click-node", "check-strictly", "filter-method"])]),
    _: 1
  }, 512)], 4)), [[_directive_loading, _ctx.treeLoading]]);
}